import Contact from "../sections/Contact";
const Contribute = () => {
  return (
    <div>
      <Contact></Contact>
    </div>
  );
};

export default Contribute;
