import { default as DonateTemp } from "../sections/Donate";
const Donate = () => {
  return (
    <div>
      {" "}
      <DonateTemp></DonateTemp>
    </div>
  );
};

export default Donate;
