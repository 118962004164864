const projects = {
  honduras: {
    imgURL: require("../imgs/honduras2019/22.jpg"), // Adjust path as needed
    title: "Honduras",
    desc: "Honduras Vein Week, sponsored by the Hackett Hemwell Patterson Foundation every March, treats over 1,500 Honduran patients suffering from varicose veins and related leg ulcers. While the mission primarily addresses venous ulcers, many patients also suffer from severe tropical ulcers that require ongoing wound care. Dr. McHutchison and T. Zimmerman, RVT, worked to educate physicians and expand care for these non-venous ulcers. In collaboration with the WHAT medical team, the Honduran Red Cross, and other partners, they established year-round access to wound care and supplies, improving patients' healing, pain relief, and quality of life.",
    metrics: [
      "1500+ Treatments Annually",
      "50+ Years of Establishment",
      "40 Category 1 CME Hours",
      "Partnered with Hackett Hemwell Patterson Foundation",
      "Providing lifesaving care and education",
    ],
    to: "/projects/honduras",
    showLearnMore: true,
  },
  vietnam: {
    imgURL: require("../imgs/vietnam/2.jpg"), // Adjust path as needed
    title: "Vietnam",
    desc: "Through dedicated educational exchanges, Dr. Hao Bui has made strides in enhancing vascular care in Vietnam. Partnering with Cho Ray Hospital's vascular department, the country's largest hospital with over three thousand beds, we conduct annual seminars, hands-on procedures, and reciprocal exchanges for Vietnamese surgeons to gain U.S. experience. Now, collaborating with Dignity Health, we aim to expand these educational initiatives across various specialties, steadily advancing healthcare accessibility.",
    metrics: [
      "Educational Exchanges Enhancing Vascular Care",
      "Partnership with Cho Ray Hospital, Vietnam's Largest",
      "Annual Seminars and Hands-On Procedures",
      "U.S. Experience for Vietnamese Surgeons",
      "Partnered with Dignity Health",
    ],
    to: "/projects/vietnam",
    showLearnMore: true,
    flipped: true,
  },
};

export default projects;
