import { Team as TeamTemp } from "../sections/Team";

const Team = () => {
  return (
    <div>
      <TeamTemp></TeamTemp>
    </div>
  );
};

export default Team;
